<template>
    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Corrigidas</h1>
                    </div>
                </div>
            </div>
        </div>
        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header border-0">
                        <h3 class="card-title">Redações</h3>
                        <div class="card-tools">
                            <a href="#" class="btn btn-tool btn-sm">
                                <i class="fas fa-download"></i>
                            </a>
                            <a href="#" class="btn btn-tool btn-sm">
                                <i class="fas fa-bars"></i>
                            </a>
                        </div>
                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table table-striped table-valign-middle">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Data da Correção</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="correction in this.corrections" :key="correction.essay_id_new" @click="show(correction.essay_id_new)">
                                    <td>{{ correction.essay_id_new }}</td>
                                    <td>{{ new Date(correction.created_at).toLocaleString() }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <Pagination @updateData="corrections = $event" @isBusy="isBusy = $event" :url="url"/>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Pagination from '../partials/Pagination.vue'
import { ESSAYS_CORRECTED_INDEX } from '../../constants/api'

export default {

    name: 'Index',
    components: { Pagination },
    data() {
        return  {
            corrections: [],
            url: ESSAYS_CORRECTED_INDEX
        }
    },

    methods: {

        show(id) {
            this.$router.push({ path: `/corrigir/${id}`})
        }

    }

}
</script>

<style scoped>

    tbody > tr {
        cursor: pointer;
    }

</style>