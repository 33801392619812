<template>
    <div class="d-flex justify-content-between">
        <p class="total"><b>Total: </b>{{ total }}</p>
        <ul class="pagination justify-content-center m-0">
            <li class="page-item" v-for="link in links" :key="link" :class="currentPage == link.label ? 'active' : ''">
                <button class="page-link" @click="getPage(link.url)" v-html="link.label" :disabled="!link.url ? true : false"></button>
            </li>
        </ul>
    </div>
</template>

<script>
import api from '../../services/api' 

export default {
    name: 'Pagination',
    props: ["url", "data", "isBusy"],

    data() {
        return {
            currentPage: 1,
            from: 0,
            links: null,
            perPage: 0,
            total: 0
        };
    },

    created() {
        this.getPage(this.url);
    },

    methods: {
        getPage(url) {

            this.$emit("isBusy", true);

            api.get(url).then(({ data }) => {
                this.currentPage = data.current_page;
                this.firstPageUrl = data.first_page_url
                this.from = data.from
                this.lastPage = data.last_page
                this.lastPageUrl = data.last_page_url
                this.links = data.links
                this.nextPageUrl = this.next_page_url
                this.path = data.path
                this.perPage = data.per_page;
                this.prevPageUrl = data.prev_page_url;
                this.to = data.to
                this.total = data.total
                this.$emit("updateData", data.data);
                this.$emit("isBusy", false);
            });
        },
    },

    watch: {
        currentPage: function() {
            this.getPage(this.currentPage);
        }
    }
};
</script>

<style scoped>
    p.total {
        margin-top: auto;
        margin-bottom: auto;
    }
</style>